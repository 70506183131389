<template>
  <div class="HSK">
    <div class="banner">
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>MCT </span>Introduction</h3>
        </div>
        <p class="_jieshao">
          Medical Chinese Proficiency Test (MCT) is a standardized language test to examine the application level of medical Chinese. It is jointly developed by the headquarters of Confucius Institute, International Chinese Test and five key medical colleges in China. Medical Chinese Proficiency Test (MCT) is mainly for international medical students, students who study medicine in Chinese outside China, and medical staff with Chinese as non-first language who conduct clinical diagnosis and treatment in Chinese inside and outside China.MCT is to test their ability to communicate in Chinese with patients, medical staff and related personnel in medical / medical scenes.
        </p>
        <div class="_detail">
          <div class="text">
            <div class="tit">1. Description of different levels</div>
            <p class="txt">MCT level 1: be able to generally understand the basic language materials related to the daily communication scenes of the hospital, be able to conduct simple communication and communication on the condition, and briefly describe the basic situation of the disease. The level of Chinese can basically meet the requirements of hospital practice. The medical professional vocabulary is about 400.</p>
            <p class="txt">MCT Level 2: be able to understand more specific language materials related to daily communication scenes in the hospital, be able to carry out complex communication and communication on the condition, and be able to describe the main situation of the disease in detail. The level of Chinese can generally meet the requirements of hospital practice. The medical professional vocabulary is about 800.</p>
            <p class="txt">MCT Level 3: be able to fully understand most of the language materials related to the daily communication scenes of the hospital, be able to carry out complex communication and communication on the condition and describe the overall situation of the disease in detail. The level of Chinese can fully meet the requirements of hospital practice. The medical professional vocabulary is about 1500.</p>
            <div class="tit">2. Content of the test</div>
            <p class="txt">Medical Chinese Proficiency Test (MCT) paper is divided into three parts: listening, reading and writing. The full score of each part is 100, with a total score of 300. There are 101 questions in the whole volume, and the examination time is about 125 minutes (including 5 minutes for candidates to fill in their personal information ). </p>
            <div class="_table">
              <div class="_row _head">
                <div class="_col w40">Structure of Test</div>
                <div class="_col w20">Number of questions </div>
                <div class="_col w20">Score</div>
                <div class="_col w20">Time (minutes)</div>
              </div>
              <div class="_row">
                <div class="_col w10">Listening </div>
                <div class="_col w30">
                  <div class="nei">
                    <div class="_colm">Listen to the dialogue and choose the correct answer.</div>
                    <div class="_colm">Listen to the dialogue and choose the correct answer.</div>
                    <div class="_colm">Listen to the dialogue and choose the correct answer.</div>
                    <div class="_colm">Listen to the passage and choose the correct answer. </div>
                  </div>
                </div>
                <div class="_col w10">
                  <div class="nei">
                    <div class="_colm">10</div>
                    <div class="_colm">10</div>
                    <div class="_colm">10</div>
                    <div class="_colm">20</div>
                  </div>
                </div>
                <div class="_col w10">50</div>
                <div class="_col">100</div>
                <div class="_col">About 35</div>
              </div>
              <div class="_row">
                <div class="_col w10">Reading</div>
                <div class="_col w30">
                  <div class="nei">
                    <div class="_colm">Choose the correct words and fill in the blanks.</div>
                    <div class="_colm">Read the paragraph and choose one that is consistent with the meaning of the paragraph.</div>
                    <div class="_colm">Read the material and choose the correct answer.</div>
                    <div class="_colm">Read the passage and choose the correct answer. </div>
                  </div>
                </div>
                <div class="_col w10">
                  <div class="nei">
                    <div class="_colm">10</div>
                    <div class="_colm">10</div>
                    <div class="_colm">10</div>
                    <div class="_colm">20</div>
                  </div>
                </div>
                <div class="_col w10">50</div>
                <div class="_col">100</div>
                <div class="_col">50</div>
              </div>
              <div class="_row">
                <div class="_col w10">Writing</div>
                <div class="_col w30">
                  Complete the outpatient medical record according to a long dialogue.
                </div>
                <div class="_col w10">
                  1
                </div>
                <div class="_col">100</div>
                <div class="_col">30</div>
              </div>
              <div class="_row">
                <div class="_col">Total</div>
                <div class="_col">/</div>
                <div class="_col">101</div>
                <div class="_col">300</div>
                <div class="_col">120</div>
              </div>
            </div>
            <div class="tit">3. Report of the result</div>
            <p class="txt">One month after the examination, candidates can log in to the Chinese test service network, enter the registration number and name, and check the results (www.chinesetest.cn).</p>
            <div class="_table"
                 style="width:400px">
              <div class="_row _head">
                <div class="_col w50">Score</div>
                <div class="_col w50">Level</div>
              </div>
              <div class="_row">
                <div class="_col w50">100-149</div>
                <div class="_col w50">Level 1</div>
              </div>
              <div class="_row">
                <div class="_col w50">150-199</div>
                <div class="_col w50">Level 2</div>
              </div>
              <div class="_row">
                <div class="_col w50">200-300</div>
                <div class="_col w50">Level 3</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
._jieshao {
  font-size: 14px;
  line-height: 1.8;
  text-indent: 2em;
  margin-bottom: 10px;
}
._detail {
  margin-top: 40px;
  .el-tabs ::v-deep {
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .text {
    padding: 20px 0;
    .txt {
      text-indent: 2em;
      font-size: 14px;
      margin-top: 10px;
      color: #363636;
      &._t {
        font-weight: bold;
        text-indent: 0;
      }
    }
  }
}
._table {
  margin: 0 auto;
  margin-top: 20px;
  ._row {
    border: 1px solid #dedede;
    margin-bottom: -1px;
    overflow: hidden;
    display: flex;
    &._head {
      background-color: #f1f1f1;
    }
    ._col {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      text-align: center;
      margin-bottom: -1px;
      min-height: 40px;
      font-size: 14px;
      &:last-child {
        border-right: none;
      }
      &.w80 {
        width: 80%;
      }

      &.w75 {
        width: 75%;
      }
      &.w60 {
        width: 60%;
      }
      &.w50 {
        width: 50%;
      }
      &.w25 {
        width: 25%;
      }
      &.w45 {
        width: 45%;
      }
      &.w40 {
        width: 40%;
      }
      &.w30 {
        width: 30%;
      }
      &.w20 {
        width: 20%;
      }
      &.w16 {
        width: 16.66667%;
      }
      &.w10 {
        width: 10%;
      }
      &.w14 {
        width: 14.285%;
      }
    }
    &._h-z3 {
      ._col {
        width: 33.333%;
        &.z2 {
          width: 66.6666%;
        }
      }
    }
  }
  .nei {
    width: 100%;
    ._colm {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dedede;
      text-align: center;
      height: 40px;
      padding: 0 10px;
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      &.tb5 {
        height: 200px;
      }
      &.tb4 {
        height: 160px;
      }
      &.tb3 {
        height: 120px;
      }
      &.tb2 {
        height: 80px;
      }
    }
  }
}
</style>